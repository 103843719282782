<template>
  <div>
    <!-- Listagem dos Grupos -->
    <b-col style="width: 100%">
      <b-card>
        <div class="card-header">
          <h4 class="card-title">
            Meus Grupos
          </h4>
          <div class="d-flex align-items-center">
            <p class="card-text font-small-2 mr-25 mb-0">
              Atualizado a 1m atrás
            </p>
          </div>
        </div>
        <div
          v-if="groups"
          class="col-lg-12 col-12"
        >
          <div class="col-lg-12 col-12">
            <div class="card card-company-table">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table"
                  >
                    <thead>
                      <tr>
                        <th>GRUPO</th>
                        <th>LINK DO GRUPO</th>
                        <th>STATUS</th>
                        <th>VENCIMENTO</th>
                        <th>PAGAMENTO</th>
                        <th>AÇOES</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="group in groups"
                      :key="group.id"
                    >

                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar rounded">
                              <div class="avatar-content">
                                <img
                                  src="@/assets/images/icons/star.svg"
                                  alt="Star svg"
                                >
                              </div>
                            </div>
                            <div>
                              <div class="font-weight-bolder">
                                {{ group.name }}
                              </div>
                              <div class="font-small-2 text-muted">
                                {{ group.type }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar bg-light-primary mr-1">
                              <div class="avatar-content">
                                <i
                                  data-feather="monitor"
                                  class="font-medium-3"
                                />
                              </div>
                            </div>
                            <a
                              target="_bank"
                              :href="group.link"
                            >{{ group.link }}</a>
                          </div>
                        </td>
                        <td class="text-nowrap">
                          <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">
                              <b-badge
                                v-if="group.status == 'pendente'"
                                variant="warning"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'aprovado'"
                                variant="success"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'recusado'"
                                variant="danger"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'banido'"
                                variant="dark"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'vencido'"
                                variant="danger"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                            </span>
                          </div>
                        </td>
                        <td class="text-nowrap">
                          <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">
                              <span
                                v-if="group.date_end == null"
                                class="font-weight-bolder mb-25"
                              >
                                <b-badge
                                  variant="dark"
                                >
                                  NAO INICIADO
                                </b-badge>
                              </span>
                              <span
                                v-else
                                class="font-weight-bolder mb-25"
                              >
                                <b-badge
                                  variant="info"
                                >
                                  {{ formatDate(group.date_end) }}
                                </b-badge>
                              </span>
                            </span>
                          </div>
                        </td>
                        <td>
                          <span
                            v-if="group.statuspayment == 'pendente'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="dark"
                            >
                              NAO PAGO
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment == 'aprovado'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="success"
                            >
                              PAGO
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment == 'recusado'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="danger"
                            >
                              RECUSADO
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment =='analise'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="warning"
                            >
                              ANALISE
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment =='aprovado_ext'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="success"
                            >
                              PAGO
                            </b-badge>
                          </span>
                          <span
                            v-if="!group.statuspayment"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="warning"
                            >
                              SEM ORDEM
                            </b-badge>
                          </span>
                        </td>
                        <td>
                          <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template v-slot:button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                              />
                            </template>
                            <b-dropdown-item>
                              <feather-icon
                                icon="SlackIcon"
                                class="mr-50"
                              />
                              <span>Renovar</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="cancelgroup">
                              <feather-icon
                                icon="TrashIcon"
                                class="mr-50"
                              />
                              <span>Cancelar</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Modal View -->

              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span
                class="ml-25"
              >Voce nao possui nenhum bot cadastrado.</span>
            </div>
          </b-alert>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :to="{ name: 'cadastrar-grupo' }"
          >
            Cadastrar Grupo
          </b-button>
        </div>
      </b-card>
    </b-col>
    <!-- Listagem dos Grupos -->
  </div>
</template>

<script>
import {
  BCard, BCol, BAlert, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import moment from 'moment'

const user = JSON.parse(localStorage.getItem('user'))

export default {
  components: {
    BAlert,
    BCard,
    BBadge,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stickyHeader: true,
      groups: [],
    }
  },
  mounted() {
    this.fetchUserGroups()
  },
  methods: {
    fetchUserGroups() {
      funcs.fetchUserGroups(user.id).then(success => {
        if (success.data[0]) {
          const returnRequest = success.data
          this.groups = returnRequest
        }
      })
    },
    formatDate(date) {
      const newdate = `${moment(date)
        .utc()
        .format('DD/MM/YYYY')}`
      this.date_end = newdate
      return newdate
    },

    cancelgroup() {
      console.log('oi')
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
